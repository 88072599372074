import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import YulingoSDK from './yulingoSDK';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

export default function() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    YulingoSDK.listCollections().then(response => {
      setData(response)
      setLoading(false);
    });
  }, []);
  
  return (<>
    <AppBar position='sticky'>
      <Container maxWidth='sm' disableGutters>
        <Toolbar>
          <IconButton color='inherit' onClick={() => history.back()}><ArrowBackIcon /></IconButton>
          <Typography variant='h6' sx={{ flexGrow: 1 }}>기본 학습장</Typography>
        </Toolbar>
      </Container>
    </AppBar>
    <Container maxWidth='sm' disableGutters>
      <List>
        {data.map((collection, index) => (
          <ListItem divider key={index}>
            <ListItemButton onClick={() => navigate(`/collections/${collection}/documents`)}>
              <ListItemText primary={collection} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Container>
    {loading && <Box sx={{ width: '100%', position: 'fixed', bottom: 0, zIndex: 3001 }}><Fade in={loading}><LinearProgress /></Fade></Box>}
  </>);
}
