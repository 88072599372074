import { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import YulingoSDK from './yulingoSDK';

import EmailIcon from '@mui/icons-material/Email';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Fade from '@mui/material/Fade';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Snackbar from '@mui/material/Snackbar';

const voices = [
  { key: 'en-AU-NatashaNeural', name: 'Natasha', country: 'Australia', gender: 'male' },
  { key: 'en-AU-WilliamNeural', name: 'William', country: 'Australia', gender: 'female' },
  { key: 'en-CA-LiamNeural', name: 'Liam', country: 'Canada', gender: 'male' },
  { key: 'en-CA-ClaraNeural', name: 'Clara', country: 'Canada', gender: 'female' },
  { key: 'en-GB-RyanNeural', name: 'Ryan', country: 'United Kingdom', gender: 'male' },
  { key: 'en-GB-LibbyNeural', name: 'Libby', country: 'United Kingdom', gender: 'female' },
  { key: 'en-GB-SoniaNeural', name: 'Sonia', country: 'United Kingdom', gender: 'female' },
  { key: 'en-US-BrandonNeural', name: 'Brandon', country: 'United States', gender: 'male' },
  { key: 'en-US-ChristopherNeural', name: 'Christopher', country: 'United States', gender: 'male' },
  { key: 'en-US-EricNeural', name: 'Eric', country: 'United States', gender: 'male' },
  { key: 'en-US-GuyNeural', name: 'Guy', country: 'United States', gender: 'male' },
  { key: 'en-US-JacobNeural', name: 'Jacob', country: 'United States', gender: 'male' },
  { key: 'en-US-AmberNeural', name: 'Amber', country: 'United States', gender: 'female' },
  { key: 'en-US-AriaNeural', name: 'Aria', country: 'United States', gender: 'female' },
  { key: 'en-US-AshleyNeural', name: 'Ashley', country: 'United States', gender: 'female' },
  { key: 'en-US-CoraNeural', name: 'Cora', country: 'United States', gender: 'female' },
  { key: 'en-US-ElizabethNeural', name: 'Elizabeth', country: 'United States', gender: 'female' },
  { key: 'en-US-JennyNeural', name: 'Jenny', country: 'United States', gender: 'female' },
  { key: 'en-US-MichelleNeural', name: 'Michelle', country: 'United States', gender: 'female' },
  { key: 'en-US-MonicaNeural', name: 'Monica', country: 'United States', gender: 'female' },
  { key: 'en-US-SaraNeural', name: 'Sara', country: 'United States', gender: 'female' },
  { key: 'en-US-AnaNeural', name: 'Ana', country: 'United States', gender: 'female' },
];

export default () => {
  const audio = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [voice, setVoice] = useState(YulingoSDK.getVoice());
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const login = async () => {
    if (navigator.userAgent.includes('KAKAOTALK')) {
      setOpen2(true);
      return;
    }
    setLoading(true);
    if (await YulingoSDK.login()) {
      navigate('/files');
      setLoading(false);
    }
    setLoading(false);
  };

  const onVoiceChange = async (event, key) => {
    setLoading(true);
    const voice = voices.filter(voice => voice.key == key)[0];
    const text = `Hi, I'm ${voice.name} from ${voice.country}, Nice to meet you.`;
    YulingoSDK.setVoice(key);
    setVoice(key);
    audio.current.src = await YulingoSDK.textToSpeech(text);
    setLoading(false);
  };

  const copyMailAddressToClipboard = () => {
    navigator.clipboard.writeText('yu-jongwon@naver.com');
    setTooltipOpen(true)
  };

  return (<>
    <audio ref={audio} autoPlay src='data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV' />
    <Container maxWidth='sm' sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box my={3}>
        <Typography variant='h4' sx={{ flexGrow: 1 }}>YULINGO</Typography>
        <Typography variant='body1' sx={{ flexGrow: 1 }}>유링고; 예문과 음성으로 학습하는 영어 단어</Typography>
      </Box>
      <Stack direction='column' spacing={2} sx={{ mt: 3 }}>
        <Button fullWidth variant='contained' size='large' onClick={() => navigate(`/collections`)}>기본 학습장</Button>
        <Button fullWidth variant='contained' size='large' color='success' onClick={() => login()}>나만의 학습장</Button>
        <Button fullWidth variant='contained' size='large' color='warning' onClick={() => setOpen(true)}>목소리 선택하기</Button>
        <Button onClick={copyMailAddressToClipboard} startIcon={<EmailIcon />} sx={{ color: 'text.secondary' }}>yu-jongwon@naver.com</Button>
      </Stack>      
    </Container>
    <Snackbar open={tooltipOpen} autoHideDuration={1000} onClose={() => setTooltipOpen(false)} message='메일 주소가 클립보드에 복사되었습니다' />
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <RadioGroup name='radio-buttons-group' value={voice} onChange={onVoiceChange}>
          <FormLabel>호주</FormLabel>
          {voices.filter(voice => voice.country == 'Australia').map(voice => (
            <FormControlLabel sx={{ ml: 1 }} key={voice.key} value={voice.key} control={<Radio />} label={voice.name} />
          ))}
          <FormLabel sx={{ mt: 3 }}>캐나다</FormLabel>
          {voices.filter(voice => voice.country == 'Canada').map(voice => (
            <FormControlLabel sx={{ ml: 1 }} key={voice.key} value={voice.key} control={<Radio />} label={voice.name} />
          ))}
          <FormLabel sx={{ mt: 3 }}>영국</FormLabel>
          {voices.filter(voice => voice.country == 'United Kingdom').map(voice => (
            <FormControlLabel sx={{ ml: 1 }} key={voice.key} value={voice.key} control={<Radio />} label={voice.name} />
          ))}
          <FormLabel sx={{ mt: 3 }}>미국 남성</FormLabel>
          {voices.filter(voice => voice.country == 'United States' && voice.gender == 'male').map(voice => (
            <FormControlLabel sx={{ ml: 1 }} key={voice.key} value={voice.key} control={<Radio />} label={voice.name} />
          ))}
          <FormLabel sx={{ mt: 3 }}>미국 여성</FormLabel>
          {voices.filter(voice => voice.country == 'United States' && voice.gender == 'female').map(voice => (
            <FormControlLabel sx={{ ml: 1 }} key={voice.key} value={voice.key} control={<Radio />} label={voice.name} />
          ))}
        </RadioGroup>
      </DialogContent>
    </Dialog>
    <Dialog open={open2} onClose={() => setOpen2(false)}>
      <DialogTitle>카카오톡에서 접속했습니다.</DialogTitle>
      <DialogContent>
        <DialogContentText>
          크롬, 사파리 등과 같은 브라우저에서 접속하세요.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen2(false)} autoFocus>확인</Button>
      </DialogActions>
    </Dialog>
    {loading && <Box sx={{ width: '100%', position: 'fixed', bottom: 0, zIndex: 3001 }}><Fade in={loading}><LinearProgress /></Fade></Box>}
  </>);
}
