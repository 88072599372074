import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import CssBaseline from '@mui/material/CssBaseline';
import App from './app';
import Collections from './collections';
import Documents from './documents';
import Document from './document';
import Files from './files';
import Sheets from './sheets';
import Values from './values';

const firebaseConfig = {
  apiKey: "AIzaSyBvtW95L3Sz_ki9k41DsMmS4dgzbDz7Krw",
  authDomain: "jongwon-yu.firebaseapp.com",
  projectId: "jongwon-yu",
  storageBucket: "jongwon-yu.appspot.com",
  messagingSenderId: "311456705638",
  appId: "1:311456705638:web:5026515a3850cfe47291a7",
  measurementId: "G-N2HXQGF2CP"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

window.addEventListener('contextmenu', event => event.preventDefault());
window.addEventListener('selectstart', event => event.preventDefault());
window.addEventListener('dragstart', event => event.preventDefault());
document.addEventListener('readystatechange', () => {
  if (document.readyState != 'complete') return;  
  gapi.load('client:auth2', init);
});

async function init() {
  await gapi.client.init({
    scope: 'https://www.googleapis.com/auth/drive.file',
    clientId: '311456705638-c9hduhcd6vspvmr1f6t9m5jf7k61ra97.apps.googleusercontent.com',
    discoveryDocs: [
      'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
      'https://sheets.googleapis.com/\$discovery/rest?version=v4'
    ]
  });

//   render(<>
//     <CssBaseline />
//     <ThemeProvider theme={createTheme({ typography: { fontFamily: [ 'Roboto', 'Noto Sans KR' ].join(',') }})}>
//       <BrowserRouter>
//         <Routes>
//           <Route path='/' element={<App />} />
//           <Route path='/collections' element={<Collections />} />
//           <Route path='/collections/:collection/documents' element={<Documents />} />
//           <Route path='/collections/:collection/documents/:document' element={<Document />} />
//           <Route path='/files' element={<Files />} />
//           <Route path='/files/:fileId/:fileName/sheets' element={<Sheets />} />
//           <Route path='/files/:fileId/:fileName/sheets/:title/values' element={<Values />} />
//           <Route path='*' element={<App />} />
//         </Routes>
//       </BrowserRouter>
//     </ThemeProvider>
//   </>, document.getElementById('app'));
}

render(<>
  <CssBaseline />
  <ThemeProvider theme={createTheme({ typography: { fontFamily: [ 'Roboto', 'Noto Sans KR' ].join(',') }})}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/collections' element={<Collections />} />
        <Route path='/collections/:collection/documents' element={<Documents />} />
        <Route path='/collections/:collection/documents/:document' element={<Document />} />
        <Route path='/files' element={<Files />} />
        <Route path='/files/:fileId/:fileName/sheets' element={<Sheets />} />
        <Route path='/files/:fileId/:fileName/sheets/:title/values' element={<Values />} />
        <Route path='*' element={<App />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
</>, document.getElementById('app'));
