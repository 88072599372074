import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import YulingoSDK from './yulingoSDK';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

export default function() {
  const params = useParams();
  const collection = params.collection;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    YulingoSDK.listDocuments(collection).then(response => {
      setData(response);
      setLoading(false);
    });
  }, []);
  
  return (<>
    <AppBar position='sticky'>
      <Container maxWidth='sm' disableGutters>
        <Toolbar>
          <IconButton color='inherit' onClick={() => history.back()}><ArrowBackIcon /></IconButton>
          <Typography variant='body1' sx={{ flexGrow: 1 }}>{collection}</Typography>
        </Toolbar>
      </Container>
    </AppBar>
    <Container maxWidth='sm' disableGutters>
      <List>
        {data.map((document, index) => (
          <ListItem divider key={index}>
            <ListItemButton onClick={() => navigate(`/collections/${collection}/documents/${document}`)}>
              <ListItemText primary={document} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Container>
    {loading && <Box sx={{ width: '100%', position: 'fixed', bottom: 0, zIndex: 3001 }}><Fade in={loading}><LinearProgress /></Fade></Box>}
  </>);
}
