import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { bindKeyboard } from 'react-swipeable-views-utils';
import YulingoSDK from './yulingoSDK';

import VolumeUpIcon from '@mui/icons-material/VolumeUpRounded';
import TranslateIcon from '@mui/icons-material/TranslateRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import SwipeableViews from 'react-swipeable-views';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';

const EnhancedSwipeableViews = bindKeyboard(SwipeableViews);

export default forwardRef(({ data, collection, document }, ref) => {
  const audio = useRef();
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const speak = async data => {
    setLoading(true);
    audio.current.src = await YulingoSDK.textToSpeech(data.english);
    setLoading(false);
  };

  const onChangeIndex = index => {
    setAlertOpen(false);
    setIndex(index);
  };

  const onClose = () => {
    audio.current.pause();
    setAlertOpen(false);
    setOpen(false)
  };

  useImperativeHandle(ref, () => ({
    open(index) {
      setIndex(index);
      setAlertOpen(false);
      setOpen(true);
    }
  }));

  return (<>
    <audio ref={audio} autoPlay src='data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV' />
    <Dialog fullScreen open={open} onClose={onClose}>
      <Container maxWidth='sm' disableGutters>
        <Toolbar>
          <IconButton color='inherit' onClick={onClose}><CloseRoundedIcon /></IconButton>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Typography variant='caption'>{collection}</Typography>
            <Typography variant='body1'>{document}</Typography>
          </Box>          
        </Toolbar>
      </Container>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', paddingTop: 1 }}>
        <Paper elevation={3} sx={{ flexGrow: 1, maxWidth: 600, maxHeight: 600, display: 'grid', gridTemplateRows: 'min-content 1fr min-content', px: 1, py: 0.5 }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Tooltip arrow key={index} placement="bottom-start" PopperProps={{ disablePortal: true }} onClose={() => setAlertOpen(!alertOpen)} open={alertOpen} disableFocusListener disableHoverListener disableTouchListener title={data[index]?.korean || 'abc'}>
              <IconButton size='large' onClick={() => setAlertOpen(!alertOpen)}><TranslateIcon /></IconButton>
            </Tooltip>
            <IconButton size='large' onClick={() => speak(data[index])}><VolumeUpIcon /></IconButton>
          </Box>
          <EnhancedSwipeableViews enableMouseEvents slideStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} index={index} containerStyle={{ height: '100%' }} onChangeIndex={onChangeIndex}>
            {data.map(({ english }, index) => (
              <Typography key={index} variant='h6' textAlign='center'>{english}</Typography>
            ))}
          </EnhancedSwipeableViews>
          <Typography variant='caption' textAlign='right' color='text.disabled'>{index + 1} / {data.length}</Typography>
        </Paper>
      </DialogContent>
    </Dialog>
    {loading && <Box sx={{ width: '100%', position: 'fixed', bottom: 0, zIndex: 3001 }}><Fade in={loading}><LinearProgress /></Fade></Box>}
  </>);
});
