import Cookies from 'universal-cookie';

const cookies = new Cookies();

function getVoice() {
  return cookies.get('voice') || 'en-US-AnaNeural';
}

function setVoice(voice) {
  cookies.set('voice', voice, { path: '/', maxAge: 60 * 60 * 24 * 365 });
}

async function login() {
  try {
    if (!gapi) return false;
    if (!gapi.auth2) return false;
    if (!gapi.auth2.getAuthInstance()) return false;
    await gapi.auth2.getAuthInstance().signIn();
    return true;
  } catch (error) {
    return false;
  }
}

async function getFiles() {
  const response = await gapi.client.drive.files.list({
    q: 'trashed = false and mimeType = "application/vnd.google-apps.spreadsheet"'
  });
  return response.result.files.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
}

async function getSheets(spreadsheetId) {
  const response = await gapi.client.sheets.spreadsheets.get({ spreadsheetId });
  return response.result.sheets.map(sheet => sheet.properties.title);
}

async function getValues(spreadsheetId, sheetTitle) {
  const response = await gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId: spreadsheetId,
    range: sheetTitle,
    majorDimension: 'ROWS'
  });
  if (!response.result.values) return [];
  return response.result.values.map(([english, korean]) => ({ english, korean }));
}

async function createFile(title) {
  const response = await gapi.client.sheets.spreadsheets.create({}, { properties: { title } });
  await gapi.client.sheets.spreadsheets.values.append({
    spreadsheetId: response.result.spreadsheetId,
    range: 'A1',
    resource: { values: [ [ 'Write down English sentences in this column', '이 열에 한국어 문장을 적으세요.' ] ] },
    valueInputOption: 'USER_ENTERED'
  });
  return await getFiles();
}

async function deleteFile(fileId) {
  await gapi.client.drive.files.update({ fileId, trashed: true });
  return await getFiles();
}

async function uploadFile(file) {
  if ([
    file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    file.name.endsWith('.tsv')
  ].every(v => !v)) return;
  await new Promise(resolve => {
    let reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      gapi.client.request({
        path: '/upload/drive/v3/files',
        method: 'POST',
        params: { 'uploadType': 'multipart' },
        headers: { 'Content-Type': 'multipart/mixed; boundary="-------314159265358979323846"' },
        body: [
          '---------314159265358979323846',
          'Content-Type: application/json',
          '',
          `{ "name": "${file.name}", "mimeType": "application/vnd.google-apps.spreadsheet" }`,
          '---------314159265358979323846',
          `Content-Type: ${file.type}`,
          'Content-Transfer-Encoding: base64',
          '',
          `${btoa(reader.result)}`,
          '---------314159265358979323846--',
        ].join('\n')
      }).execute(resolve);
    }
  });
  return await getFiles();
}

async function downloadFile(fileId) {
  window.location.assign(`https://docs.google.com/spreadsheets/d/${fileId}/export?format=xlsx`);
}

async function listCollections() {
  // const url = `http://localhost:5001/jongwon-yu/asia-northeast3/yulingo/collections/${collection}`;
  const url = `https://asia-northeast3-jongwon-yu.cloudfunctions.net/yulingo/collections`;
  const response = await fetch(url);
  const data = await response.json();
  return data;
}

async function listDocuments(collection) {
  // const url = `http://localhost:5001/jongwon-yu/asia-northeast3/yulingo/collections/${collection}`;
  const url = `https://asia-northeast3-jongwon-yu.cloudfunctions.net/yulingo/collections/${collection}`;
  const response = await fetch(url);
  const data = await response.json();
  return data;
}

async function getDocument(collection, document) {
  // const url = `http://localhost:5001/jongwon-yu/asia-northeast3/yulingo/collections/${collection}/documents/${document}`;
  const url = `https://asia-northeast3-jongwon-yu.cloudfunctions.net/yulingo/collections/${collection}/documents/${document}`;
  const response = await fetch(url);
  const data = await response.json();
  return data.sentences;
}

async function textToSpeech(text) {
  const voice = await getVoice();
  // const url = `http://localhost:5001/jongwon-yu/asia-northeast3/yulingo/voices/${voice}`;
  const url = `https://asia-northeast3-jongwon-yu.cloudfunctions.net/yulingo/voices/${voice}`;
  const response = await fetch(url, { method: 'POST', body: text });
  return await response.json();
}

export default {
  login,
  getFiles,
  getSheets,
  getValues,
  createFile,
  deleteFile,
  uploadFile,
  downloadFile,
  getVoice,
  setVoice,
  listCollections,
  listDocuments,
  getDocument,
  textToSpeech,
};

// async function speechToText({ token, text } = {}) {
//   const speechConfig = SpeechConfig.fromAuthorizationToken(token, 'koreacentral');
//   const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
//   speechConfig.speechRecognitionLanguage = 'en-US';
//   const speechRecognizer = new SpeechRecognizer(speechConfig, audioConfig);
//   const pronunciationAssessmentConfig = new PronunciationAssessmentConfig(text, PronunciationAssessmentGradingSystem.FivePoint, PronunciationAssessmentGranularity.Word, true);
//   pronunciationAssessmentConfig.applyTo(speechRecognizer);
//   const response = await new Promise(resolve => speechRecognizer.recognizeOnceAsync(response => resolve(response)));
//   speechRecognizer.close();
//   if (response.reason !== 3) return false;
//   const result = PronunciationAssessmentResult.fromResult(response).detailResult;
//   return {
//     accuracyScore: result.PronunciationAssessment.AccuracyScore,
//     fluencyScore: result.PronunciationAssessment.FluencyScore,
//     totalScore: result.PronunciationAssessment.PronScore,
//     severity: result.Words.findIndex(word => word.PronunciationAssessment.ErrorType != 'None') === -1 ? 'success' : 'error',
//     words: result.Words.map(word => ({
//       text: word.Word,
//       decoration: word.PronunciationAssessment.ErrorType == 'Omission' ? 'line-through' : 'none',
//       color: word.PronunciationAssessment.ErrorType == 'Insertion' ? 'red' : 'inherit',
//       opacity: word.PronunciationAssessment.AccuracyScore ? word.PronunciationAssessment.AccuracyScore * 0.2 : 1,
//     }))
//   };
// }

// async function getStates() {
//   const f = httpsCallable(functions, 'getStates');
//   const response = await f();
//   return response.data;
// }

// async function issueToken() {
//   const f = httpsCallable(functions, 'issueToken');
//   const response = await f();
//   return response.data;
// }


// async function getFileList() {
//   const response = await gapi.client.drive.files.list({ q: 'trashed=false' });
//   return response.result.files;
// };

// async function getSpreadsheet() {
//   let spreadsheetId = await getSpreadsheetId();
//   if (spreadsheetId === null) {
//     spreadsheetId = await createSpreadsheet();
//     await appendSampleSentence(spreadsheetId);
//   }
//   const spreadsheet = await gapi.client.sheets.spreadsheets.get({ spreadsheetId });
//   const spreadsheetUrl = spreadsheet.result.spreadsheetUrl;
//   const sheetTitles = spreadsheet.result.sheets.map(sheet => sheet.properties.title);
//   const firstSheetTitle = spreadsheet.result.sheets[0].properties.title;
//   const firstSheetValues = await getSpreadsheetValues(spreadsheetId, firstSheetTitle);
//   return {
//     spreadsheetId,
//     spreadsheetUrl,
//     sheetTitles,
//     firstSheetTitle,
//     firstSheetValues
//   };
// }

// async function getSpreadsheetId() {
//   const response = await gapi.client.drive.files.list({
//     q: 'trashed=false'
//   });
//   return response.result.files.length === 0 ? null : response.result.files[0].id;
// }

// async function createSpreadsheet() {
//   const response = await gapi.client.sheets.spreadsheets.create({}, {
//     properties: { title: 'Yulingo' }
//   });
//   return response.result.spreadsheetId;
// }

// async function appendSampleSentence(spreadsheetId) {
//   await gapi.client.sheets.spreadsheets.values.append({
//     spreadsheetId: spreadsheetId,
//     range: 'A1',
//     resource: {
//       values: [
//         [
//           'This is a sentence example',
//           '이것은 예제 문장입니다.'
//         ]
//       ]
//     },
//     valueInputOption: 'USER_ENTERED'
//   });
// }

// async function getSpreadsheetValues(spreadsheetId, sheetTitle) {
//   try {
//     const response = await gapi.client.sheets.spreadsheets.values.get({
//       spreadsheetId: spreadsheetId,
//       range: sheetTitle,
//       majorDimension: 'ROWS'
//     });
//     return response.result.values.map(([english, korean]) => ({ english, korean }));
//   } catch {
//     return [];
//   }
// }


// window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;



// import {
//   AudioConfig,
//   SpeechConfig,
//   PronunciationAssessmentConfig,
//   SpeechRecognizer,
//   SpeechSynthesizer,
//   SpeechSynthesisOutputFormat,
//   PronunciationAssessmentGradingSystem,
//   PronunciationAssessmentGranularity,
//   PronunciationAssessmentResult,
// } from 'microsoft-cognitiveservices-speech-sdk';

    // const credential = await GoogleAuthProvider.credential(user.getAuthResponse().id_token);
    // const result = await signInWithCredential(getAuth(), credential);