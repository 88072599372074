import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import YulingoSDK from './yulingoSDK';
import SpeechCard from './speechCard';
import KoreanCard from './koreanCard';
import EnglishCard from './englishCard';
import TrainingPhrase from './trainingPhrase';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Fade from '@mui/material/Fade';

export default function() {
  const params = useParams();
  const collection = params.collection;
  const document = params.document;
  const speechCard = useRef();
  const koreanCard = useRef();
  const englishCard = useRef();
  const trainingPhrase = useRef();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    YulingoSDK.getDocument(collection, document).then(response => {
      setData(response);
      setLoading(false);
    });
  }, []);
  
  return (<>
    <AppBar position='sticky'>
      <Container maxWidth='sm' disableGutters>
        <Toolbar>
          <IconButton color='inherit' onClick={() => history.back()}><ArrowBackIcon /></IconButton>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Typography variant='caption'>{collection}</Typography>
            <Typography variant='body1'>{document}</Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    <Container maxWidth='sm' disableGutters>
      {data.map((value, index) => (
        <Card key={index} sx={{ mx: 1, my: 3 }} variant='outlined'>
          <CardContent sx={{ position: 'relative' }}>
            <Typography variant='h6' gutterBottom sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {value.korean.split(/\s+/).map((word, index) => (
                <span key={index} style={{ wordBreak: 'keep-all', marginRight: '0.3rem', opacity: word == '/' ? 0.38 : 1 }}>{word}</span>
              ))}
            </Typography>
            <Typography variant='body2' color='text.secondary'>{value.english}</Typography>            
          </CardContent>
          <CardActions>
            <Button sx={{ color: 'text.primary' }} onClick={() => englishCard.current.open(index)}>영어 카드</Button>
            <Button sx={{ color: 'text.primary' }} onClick={() => koreanCard.current.open(index)}>한국어 카드</Button>
            <Button sx={{ color: 'text.primary' }} onClick={() => speechCard.current.open(index)}>음성 카드</Button>
            <Button sx={{ color: 'text.primary' }} onClick={() => trainingPhrase.current.open(index)}>구문 카드</Button>
          </CardActions>
        </Card>
      ))}
    </Container>
    <SpeechCard ref={speechCard} data={data} collection={collection} document={document} />
    <KoreanCard ref={koreanCard} data={data} collection={collection} document={document} />
    <EnglishCard ref={englishCard} data={data} collection={collection} document={document} />
    <TrainingPhrase ref={trainingPhrase} data={data} collection={collection} document={document} />
    {loading && <Box sx={{ width: '100%', position: 'fixed', bottom: 0, zIndex: 3001 }}><Fade in={loading}><LinearProgress /></Fade></Box>}
  </>);
}
