import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { bindKeyboard } from 'react-swipeable-views-utils';
import YulingoSDK from './yulingoSDK';

import VolumeUpIcon from '@mui/icons-material/VolumeUpRounded';
import TranslateIcon from '@mui/icons-material/TranslateRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import SwipeableViews from 'react-swipeable-views';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

function makeWords(english) {
  return english.match(/[a-zA-Z. '’]/g).join('').split(' ').filter(word => word).map(word => word.endsWith('.') ? word.slice(0, -1) : word);
}

function makeOptions(english, index) {
  const words = makeWords(english);
  const result = [...words.splice(index, 1)];
  for (let i = 0; i < 3; i++) {
    index = Math.floor(Math.random() * words.length);
    result.push(...words.splice(index, 1));
  }
  return result.sort(() => Math.random() - 0.5);
}

function checkOption(english, index, word) {
  const words = makeWords(english);
  return words[index].toLowerCase() == word.toLowerCase();
}

function checkLastOption(english, index) {
  const words = makeWords(english);
  return words.length - 1 === index;
}

function TrainingPhraseContent({english, korean}) {
  const initialState = {
    severity: 'info',
    input: '다음 중 적절한 단어를 고르세요.',
    index: 0,
    options: makeOptions(english, 0)
  };

  const [state, setState] = useState(initialState);
  const portrait = useMediaQuery('(orientation: portrait)');

  const selectOption = option => {
    const isLast = checkLastOption(english, state.index);
    const isCorrect = checkOption(english, state.index, option);
    if (isCorrect && isLast) setState({
      ...state,
      severity: 'success',
      input: english,
      options: [],
    });
    else if (isCorrect) setState({
      ...state,
      severity: 'info',
      index: state.index + 1,
      input: english,
      options: makeOptions(english, state.index + 1),
      input: (state.index === 0 ? '' : state.input) + ' ' + option,
    });
    else setState({
      ...state,
      severity: 'error',
    });
  };

  return (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 1 }}>
      <Typography variant='body1' textAlign='center' sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {korean.split(/\s+/).map((word, index) => (
          <span key={index} style={{ wordBreak: 'keep-all', marginRight: '0.3rem', opacity: word == '/' ? 0.38 : 1 }}>{word}</span>
        ))}
      </Typography>
      <Alert severity={state?.severity} variant='outlined' sx={{ minWidth: 290 }}>{state?.input}</Alert>
      <Box sx={{ display: 'grid', gridTemplateColumns: `repeat(${portrait ? 2 : 4 }, 1fr)`, columnGap: 1 }}>
        {state.options?.map((option, index) => (
          <Button size='large' sx={{ color: 'text.primary' }} key={index} onClick={() => selectOption(option)}>{option}</Button>
        ))}
      </Box>
    </Box>
  </Box>);
};

const EnhancedSwipeableViews = bindKeyboard(SwipeableViews);

export default forwardRef(({ data, collection, document }, ref) => {
  const audio = useRef();
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const speak = async data => {
    setLoading(true);
    audio.current.src = await YulingoSDK.textToSpeech(data.english);
    setLoading(false);
  };

  const onChangeIndex = index => {
    setAlertOpen(false);
    setIndex(index);
  };

  const onClose = () => {
    audio.current.pause();
    setAlertOpen(false);
    setOpen(false)
  };

  useImperativeHandle(ref, () => ({
    open(index) {
      setIndex(index);
      setAlertOpen(false);
      setOpen(true);
    }
  }));

  return (<>
    <audio ref={audio} autoPlay src='data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV' />
    <Dialog fullScreen open={open} onClose={onClose}>
      <Container maxWidth='sm' disableGutters>
        <Toolbar>
          <IconButton color='inherit' onClick={onClose}><CloseRoundedIcon /></IconButton>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Typography variant='caption'>{collection}</Typography>
            <Typography variant='body1'>{document}</Typography>
          </Box>          
        </Toolbar>
      </Container>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', paddingTop: 1 }}>
        <Paper elevation={3} sx={{ flexGrow: 1, maxWidth: 600, maxHeight: 600, display: 'grid', gridTemplateRows: 'min-content 1fr min-content', px: 1, py: 0.5 }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Tooltip arrow key={index} placement="bottom-start" PopperProps={{ disablePortal: true }} onClose={() => setAlertOpen(!alertOpen)} open={alertOpen} disableFocusListener disableHoverListener disableTouchListener title={data[index]?.english || 'abc'}>
              <IconButton size='large' onClick={() => setAlertOpen(!alertOpen)}><TranslateIcon /></IconButton>
            </Tooltip>
            <IconButton size='large' onClick={() => speak(data[index])}><VolumeUpIcon /></IconButton>
          </Box>
          <EnhancedSwipeableViews slideStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} enableMouseEvents index={index} containerStyle={{ height: '100%' }} onChangeIndex={onChangeIndex}>
            {data.map(({ english, korean }, index) => (
              <TrainingPhraseContent key={index} english={english} korean={korean} />
            ))}
          </EnhancedSwipeableViews>
          <Typography variant='caption' textAlign='right' color='text.disabled'>{index + 1} / {data.length}</Typography>
        </Paper>
      </DialogContent>
    </Dialog>
    {loading && <Box sx={{ width: '100%', position: 'fixed', bottom: 0, zIndex: 3001 }}><Fade in={loading}><LinearProgress /></Fade></Box>}
  </>);
});
