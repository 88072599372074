import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import YulingoSDK from './yulingoSDK';

import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreIcon from '@mui/icons-material/MoreVert';
import FolderIcon from '@mui/icons-material/Folder';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

export default function() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileMenuAnchor, setFileMenuAnchor] = useState(null);
  const [fileId, setFileId] = useState('');
  const [newDialogOpen, setNewDialogOpen] = useState(false);
  const [newFileName, setNewFileName] = useState('');
  const [data, setData] = useState([]);
  const input = useRef();

  const getData = async () => {
    setLoading(true);
    const files = await YulingoSDK.getFiles();
    if (files.length !== 0) setData(files);
    else setData(await YulingoSDK.createFile('이름 없는 학습장'));
    setLoading(false);
  };

  const createFile = async () => {
    if (newFileName.trim().length === 0) return;
    setNewDialogOpen(false);
    setLoading(true);
    setData(await YulingoSDK.createFile(newFileName.trim()));
    setLoading(false);
  };

  const uploadFile = async event => {
    setLoading(true);
    setData(await YulingoSDK.uploadFile(event.target.files[0]));
    setLoading(false);
  };

  const openFile = () => {
    window.open(`https://docs.google.com/spreadsheets/d/${fileId}/edit`);
  };

  const downloadFile = async () => {
    setLoading(true);
    await YulingoSDK.downloadFile(fileId);
    setLoading(false);
  };

  const deleteFile = async () => {
    setLoading(true);
    setData(await YulingoSDK.deleteFile(fileId));
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  
  return (<>
    <input ref={input} style={{ display: 'none' }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .tsv" onChange={event => uploadFile(event)} type="file" />
    <AppBar position='sticky'>
      <Container maxWidth='sm' disableGutters>
        <Toolbar>
          <IconButton color='inherit' onClick={() => history.back()}><ArrowBackIcon /></IconButton>
          <Typography variant='h6' sx={{ flexGrow: 1 }}>나만의 학습장</Typography>
          <IconButton size="large" edge="end" color="inherit" onClick={e => setAnchorEl(e.currentTarget)}><AccountCircleIcon /></IconButton>
        </Toolbar>
      </Container>
    </AppBar>
    <Container maxWidth='sm' disableGutters>
      <List>
        {data.map((file, index) => (
          <ListItem divider key={index} secondaryAction={<IconButton onClick={e => { setFileId(file.id); setFileMenuAnchor(e.currentTarget); }}><MoreIcon /></IconButton>}>
            <ListItemButton onClick={() => navigate(`/files/${file.id}/${file.name}/sheets`)}>
              <ListItemText primary={file.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Container>
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={() => { setAnchorEl(null); setNewDialogOpen(true); }}>
        <ListItemIcon><AddBoxIcon /></ListItemIcon>
        <ListItemText>새 학습장</ListItemText>
      </MenuItem>      
      <MenuItem onClick={() => { setAnchorEl(null); input.current.click() }}>
        <ListItemIcon><UploadIcon /></ListItemIcon>
        <ListItemText>학습장 업로드</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => { setAnchorEl(null); window.open(`https://drive.google.com/drive/my-drive`); }}>
        <ListItemIcon><FolderIcon /></ListItemIcon>
        <ListItemText>내 드라이브</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => { setAnchorEl(null); window.open(`https://drive.google.com/drive/trash`); }}>
        <ListItemIcon><DeleteIcon /></ListItemIcon>
        <ListItemText>휴지통</ListItemText>
      </MenuItem>
    </Menu>
    <Menu anchorEl={fileMenuAnchor} open={Boolean(fileMenuAnchor)} onClose={() => setFileMenuAnchor(null)}>
      <MenuItem onClick={() => { setFileMenuAnchor(null); openFile(); }}>
        <ListItemIcon><EditIcon /></ListItemIcon>
        <ListItemText>수정</ListItemText>
      </MenuItem>      
      <MenuItem onClick={() => { setFileMenuAnchor(null); downloadFile() }}>
        <ListItemIcon><DownloadIcon /></ListItemIcon>
        <ListItemText>다운로드</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => { setFileMenuAnchor(null); deleteFile(); }}>
        <ListItemIcon><DeleteIcon /></ListItemIcon>
        <ListItemText>삭제</ListItemText>
      </MenuItem>
    </Menu>
    <Dialog open={newDialogOpen} onClose={() => setNewDialogOpen(false)}>
      <DialogTitle>새 문서 만들기</DialogTitle>
      <DialogContent sx={{ width: 300 }}>
        <TextField autoFocus fullWidth value={newFileName} onChange={e => setNewFileName(e.target.value)} spellCheck='false' margin='dense' variant='standard' label='파일 이름' />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setNewDialogOpen(false)}>취소</Button>
        <Button onClick={createFile}>확인</Button>
      </DialogActions>
    </Dialog>
    {loading && <Box sx={{ width: '100%', position: 'fixed', bottom: 0, zIndex: 3001 }}><Fade in={loading}><LinearProgress /></Fade></Box>}
  </>);
}
